import React from "react";
import "twin.macro";
import { BlankPageNoImage, LocationBlock } from "../components/common";
import { SEO } from "../components";
import { graphql } from "gatsby";
import { JobCard } from "../components/pages/careers";

const Jobs = ({ data: { allStrapiJob } }) => {
  const vacancies = allStrapiJob.edges.map((a) => a.node);
  return (
    <>
      <SEO title="Careers at the Ear Place" />
      <BlankPageNoImage
        header="Careers at the Ear Place"
        ariaLabel="Careers at the Ear Place"
      >
        <section tw="relative py-8 bg-white overflow-hidden">
          <div tw="px-4 relative sm:px-6 lg:px-8">
            <div tw="max-w-prose mx-auto prose prose-lg">
              <div tw="text-xl text-gray-500 leading-8 font-light text-center">
                {
                  "We're always looking for passionate and experienced individuals to join our team."
                }
              </div>
            </div>
          </div>
        </section>
        <section tw="mx-auto max-w-screen-xl">
          <h2 tw="text-center text-3xl pt-8 font-display uppercase">
            Current Vacancies
          </h2>
          {vacancies.length ? (
            <div tw="mt-12 max-w-screen-xl mx-auto flex flex-col justify-around items-stretch md:(space-x-5 flex-row) px-4 sm:px-6 lg:px-8">
              {vacancies.map((post) => (
                <JobCard {...post} key={post.slug} />
              ))}
            </div>
          ) : (
            <p tw="font-light mt-12 max-w-screen-xl mx-auto text-center">
              We have no vacancies open, but we'd still love to hear from you at{" "}
              <a
                href="mailto:recruitment@earplace.co.uk"
                target="_blank"
                rel="noopener noreferrer"
                tw="underline hover:text-gray-700"
              >
                recruitment@earplace.co.uk
              </a>
            </p>
          )}
        </section>
        <LocationBlock />
      </BlankPageNoImage>
    </>
  );
};

export default Jobs;

export const query = graphql`
  query jobsPageQuery {
    allStrapiJob {
      edges {
        node {
          contactEmail
          content {
            data {
              content
            }
          }
          updatedAt
          location
          slug
          role
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`;
